@import '../../../../node_modules/@capcenter/shared/src/styling/Breakpoints';

body {
  overflow-x: hidden;
}

main.realty {
  font-family: 'acumin-pro', Arial, sans-serif;



  .registered-trademark-heading {
    color: #0C2C48;

    width: 10px;
    height: 10px;
    margin-left: 5px;
    margin-top: -20px;
  }

  .registered-trademark {
    width: 5px;
    height: 5px;

    margin-top: -10px;
    margin-left: 1px;

    &.white {
      color: #fff;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'proxima-nova', Arial, sans-serif;
    font-weight: 700;
    color: #0C2C48;
  }

  p {
    padding: 0;
  }

  .fa-info-circle {
    color: #0C7BE1;
    text-indent: 0;
  }

  .btn-primary {
    background-color: #ea701b;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;

    &:hover {
      background-color: #b44100;
      text-decoration: none;
    }
  }

  .btn-secondary {
    background-color: transparent;
    border: 1px solid #ea701b;
    color: #ea701b;
    padding: 8px 15px;
    border-radius: 5px;

    &:hover {
      background-color: #ea701b;
      color: white;
      text-decoration: none;
    }

    &.small {
      padding: 2px 10px;
    }
  }

  .details {
    padding-top: 10px;
    color: #647778;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 0px;
  }

  .rtb-sub-title {
    color: #ea701b;
    font-size: 20px;
    padding: 10px 0 5px;
    font-family: proxima-nova, Arial, sans-serif;
  }

  .section-search-hero {
    background-color: #FFF5ED;
    color: #0C2C48;

    .fa-info-circle {
      font-size: 16px;
    }

    .row {
      height: 263px;
    }

    .search-group {
      width: 100%;
      display:inline-block;
      padding: 15px 15px 20px 15px;

      &.buy, &.sell, &.preapproval {
        background-image: url("../assets/zero-listing-fees-small.png");
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: 120px;
      }

      a {
        color: #0C2C48;
      }

      .hero-rule {
        display: inline-block;
        width: 40px;
        margin-top: 15px;
        margin-bottom: 5px;
        border: 3px solid #ea701b;
      }

      .search-bar {
        .input-group {
          width: 70%;
        }
        .form-control {
          font-size: 12px;
        }
      }
    }

    .hero-right.buy {
      display:none;
      width: 100%;
      height: 400px;
    }

    .hero-right.sell {
      display:none;
      width: 100%;
      height: 400px;
      background-image: url("../assets/veteran-family-moving-after-selling-home.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .hero-right.preapproval {
      display:none;
      width:100%;
      height: 400px;
      background-image: url("../assets/best-mortgage-preapproved.png");
      background-repeat: no-repeat;

      background-position: center;
      background-size: cover;
    }

    h1 {
      font-size: 25px;
      line-height: 30px;
      font-weight: bold;
      font-family: proxima-nova, Arial, sans-serif;
      margin-bottom: 20px;
    }

    .tab-container {
      margin-top: 25px;
      margin-bottom: 5px;

      .tab {
        text-align: center;
        display: inline-block;
        min-width: 60px;
        max-width: 130px;
        margin: 10px 0px 15px 0;
        font-size: 15px;
        font-family: proxima-nova, Arial, sans-serif;

        a {
          color: #0C2C48;
          width: 100%;
          padding: 5px 10px 5px 10px;
          text-decoration: none;
          border-bottom: 5px solid transparent;

          &:hover {
            color: #b44100;
            border-bottom: 5px solid #b44100;
          }
        }

        &.active {
          a {
            border-bottom: 5px solid #F47C28;

            &:hover {
              color: #F47C28;
            }
          }
        }
      }
    }
  }

  h2 {
    font-weight: 700;
    font-size: 22px;
  }

  .rtb-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    align-content: center;
    flex-wrap: wrap;
    min-height: 70px;

    figure {
      width: 100%;

      img, iframe {
        margin: 25px 0;
        width: 320px;
        background-color: #FFF5ED;
        border-radius: 15px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      }

      figcaption {
        display:none;
      }
    }

    .rtb-text {
      padding: 25px 15px;
      width: 100%;
    }
  }

  .section-rates, .section-down-payment {

    margin-top: 25px;

    svg {
      margin-right: 5px;
    }

    .fa-chevron-double-down {
      margin-right: 10px;
      color:#ea701b;
    }

    .fa-percentage {
      margin-right: 10px;
      color:#ea701b;
    }

    .box-container-wrapper {
      background-color: #fff5ed;
      border: 1px solid #FFF0E5;
      border-radius: 25px;
      padding: 15px;
      margin: 15px;

      .box-container {
        margin-bottom: 0px;
      }

      .box-card {
        text-align: center;
        margin: 10px;


        .audience {
          margin-top: 15px;
          font-weight: bold;
          font-size: 15px;
          line-height: 20px;
        }
        .program {
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
        }
        .number {
          font-size: 22px;
          color: #ea701b;
          line-height: 60px;
        }
        .points, .apr {
        }
      }

      .rates-last-updated {
        margin-top: 20px;
        font-size: 14px;
        font-family: proxima-nova, Arial, sans-serif;
      }
    }
  }

  .section-reviews {
    padding: 45px 15px 25px 15px;
    column-gap: 10px;
    text-align:center;

    img, svg {
      margin: 10px 10px 0 10px;
      max-height: 50px;
      max-width: 130px;
    }
    .caption-title {
      font-family: proxima-nova, Arial, sans-serif;
      font-size: 16px;
      color: #435759;
      font-weight: 600;
      margin-bottom: 0px;
      margin-block-end: 0em;
    }
    .caption-text {
      font-size: 12px;
      color: #435759;
      font-weight: 500;
    }
  }

  .section-savings {
    min-height: 400px;
    margin-top: 25px;

    &.rtb-flex {
      justify-content: start;
    }

    svg {
      margin-right: 5px;
    }

    .calculator {
      padding: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .circle {
      color: white;
      background-color: #0C2C48;
      border-radius: 5px;
      height: 25px;
      display: block;
      text-align: center;
    }

    .fa-sack-dollar {
      color: #007f4e;
      margin-right: 15px;
    }

    .savings {
      color: #007f4e;
    }

    .savings-header {
      font-family: proxima-nova, Arial, sans-serif;
      font-size: 14px;
      font-weight: bold;
      color: #0C2C48;
      padding-bottom: 5px;
      margin-bottom: 10px;
      border-bottom: 1px solid #e5e5e4;
    }

    .savings-one {
      padding-top: 7px;
      font-family: proxima-nova, Arial, sans-serif;
      background-color: #ea701b;
      color: white;
      font-weight: bold;
      vertical-align: middle;
      text-align: center;
    }
    .savings-two-total {
      font-weight: bold;
    }
    .savings-two {
      font-family: proxima-nova, Arial, sans-serif;

      .form-control {
        font-size: 13px;
      }
    }
    .savings-three {
      padding-top: 5px;
      font-family: proxima-nova, Arial, sans-serif;
      color: #007f4e;
      font-size: 15px;

      &.total {
        font-size: 17px;
        font-weight: bold;
      }
    }
  }

  .loader {
    width: 1rem;
    height: 1rem;
    border: 5px solid #ea701b;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: .5rem;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }

  .section-data {
    margin-top: 15px;
    padding: 15px;

    .capcenter-logo {
      margin-top: 5px;
      height: 30px;
      display: none;
    }

    h2 {
      line-height: 35px;
    }

    h3 {
      color: #ea701b;
      font-family: Omnes-pro,Helvetica,Arial,sans-serif;
      font-size: 25px;
      line-height: 28px;
      font-weight: 600;
      margin: 30px 25px 10px 25px;
      width: 200px;
      text-align: center;
    }
    p, .data-caption {
      text-align: center;
      width: 200px;
      font-family: Omnes-pro,Helvetica,Arial,sans-serif;
      font-size: 18px;
      line-height: 22px;
      margin: 15px 25px;
    }

    div.large {
      width: 100%;

      h3 {
        width: 90%;
        font-size: 22px;
        margin: 15px;
        text-align: left;
      }
      p {
        text-align: left;
        width: 90%;
        font-size: 16px;
        margin: 15px;
      }
    }
  }

  .section-process {
    margin-top: 25px;
    padding: 25px 25px;

    .fa-project-diagram {
      color: #ea701b;
    }

    h2 {
      margin-bottom: 25px;
    }

    ol {
      margin:0 0 1.5em;
      padding:0;
      counter-reset:item;
    }

    ol>li {
      margin:0 0 15px 0;
      padding:0 0 0 2em;
      text-indent:-2em;
      list-style-type:none;
      counter-increment:item;
    }

    ol>li:before {
      display:inline-block;
      width:1.5em;
      padding-right:15px;
      font-weight:bold;
      text-align:right;
      content:counter(item) ".";
    }

    a {
      &:hover {
        text-decoration: none;
      }
    }
  }

  .section-description {
    margin-top: 5px;
    padding: 25px 15px;

    svg {
      margin-right: 5px;
      height: 35px;
    }

    h2 {
      text-align: center;
      margin-bottom: 25px;
    }
  }

  .section-team, .section-savings {
    .fa-users, .fa-sign {
      color: #ea701b;
    }

    .profile-container {
      display: flex;
      margin-bottom: 25px;

      .profile {
        text-align: center;
        width: 22%;

        .profile-image {
          border-radius: 50%;
          width: 80px;
        }

        .profile-name {
          text-align: center;
          font-size: 12px;
          margin: 5px 5px 0 5px;
          font-weight: bold;
          min-width: 80px;
          font-family:proxima-nova, Arial, sans-serif;
        }
        .profile-title {
          text-align: center;
          font-size: 10px;
          min-width: 80px;
          font-family:proxima-nova, Arial, sans-serif;
        }
      }
    }
  }

  .section-testimonials {
    .card {
      border-radius: 15px;
      border-color: #FFF0E5;
      background-color: #FFF0E5;

      .fa-quote-left {
        color: #ea701b;
        margin-right: 10px;
      }

      .carousel-control-prev, .carousel-control-next {
        border: none;
        background-color: #FFF0E5;
        color: #ea701b;

        .carousel-control-prev-icon, .carousel-control-next-icon {
          color: #ea701b;
        }
        .visually-hidden {
          visibility: hidden;
        }
      }

      button {
        &.active {
          background-color: #ea701b;
        }
        border: none;
        background-color: #0C2C48;
        margin: 5px;
      }
    }
  }

  .section-faq {
    margin-top: 15px;
    padding: 25px 15px;

    .fa-question-circle {
      color: #ea701b;
    }

    .rtb-flex {
      margin-top: 25px;
      margin-bottom: 25px;

      div {
        width: 100%;
      }

      button {
        cursor: pointer;
        text-decoration: none;
        border: none;
        width: 100%;
        display: block;
        text-align: left;
        background-color: transparent;

        &:hover {
          text-decoration: none;
        }
        &:focus, &:active {
          text-decoration: none;
          border: none;
          outline: none;
        }
      }
    }

    h3 {
      color: #0C2C48;
      font-size: 14px;
      padding: 15px 0px 15px 0px;
      border-radius: 5px;
      margin-bottom: 0;

      &:hover {
        background-color: #FFF5ED;
      }

      .faq-number {
        margin-right: 15px;
      }

      i {
        display:none;
        margin-top: 5px;
        width: 50px;
        text-align: center;
        float: right;
      }
    }

    article {
    }

    .faq-answer {
      padding: 25px;
    }

    .show-more {
      margin-top: 25px;
      text-align: center;
    }

    .show-more-link {
      color: #0C2C48;
    }
  }

  .section-refinance {
    &.rtb-flex {
      align-items: start;
    }

    .fa-question, .fa-user-clock, .fa-user-chart {
      color: #ea701b;
    }
  }

  .section-next-steps {
    .fa-check-square {
      color: #ea701b;
    }

    a {
      &:hover {
        text-decoration: none;
      }
    }
  }

  .section-listings {
    padding: 15px;

    .capcenter-logo {
      height: 25px;
      display:none;
    }

    .rtb-flex {
      padding: 25px;
      justify-content: space-evenly;
    }

    h2 {
    }
    img {
      max-height: 400px;
      max-width: 250px;
      background-color: #FFF5ED;
    }

    .listing-card {
      border-radius: 15px;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      border: 1px solid transparent;
      margin-bottom: 15px;

      &:hover {
        text-decoration: none;
        border: 1px solid #ea701b;
      }

      .listing-photo {
        img {
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
        }
      }
      .listing-details {
        padding: 15px;
        color: #0C2C48;

        .listing-price {
          font-family: proxima-nova, Arial, sans-serif;
          font-size: 30px;
          line-height: 30px;
          margin: 0;
          color: #ea701b;
        }

        .listing-features {
          font-size: 14px;
        }
        .listing-mls {
          margin-top: 10px;
          margin-bottom: 5px;
          font-size: 12px;
        }
      }
    }
  }

  .section-homes-for-sale {
    padding: 25px 15px;

    .fa-search {
      color: #ea701b;
    }

    .rtb-flex {
      margin-top: 15px;
      margin-bottom: 15px;
      align-items: flex-start;
      justify-content: center;
    }

    h3 {
      font-size: 14px;
      margin: 10px 0px;
      font-weight: bold;
      a {
        color: #0C2C48;
      }
    }

    h4 {
      font-size: 12px;
      margin: 10px 0px;
      font-weight: normal;
      a {
        color: #0C2C48;
      }

    }

    .homes-for-sale-state {
      margin: 15px 15px 15px 15px;
      width: 300px;
    }
    .homes-for-sale-city {
    }

    .show-more {
      display: none;
      width: 100%;
      font-size: 18px;
      text-align: center;
    }
    .show-more-link {
      width: 100%;
      font-size: 14px;
      text-align: center;
      color: #0C2C48;
    }
  }
}

@media (max-width: $small) { 
  .section-savings {
    .calculator {
      padding: 1rem !important;
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}

@media (min-width: $small) {  ////////////////////////////////////////////////////////////////////////////////////////////////


  main.realty {
    font-family: 'acumin-pro', Arial, sans-serif;

    h1, h2, h3, h4, h5, h6 {
      font-family: 'proxima-nova', Arial, sans-serif;
      font-weight: 700;
      color: #0C2C48;
    }

    p {
      padding: 0;
    }

    .fa-info-circle {
      color: #0C7BE1;
      text-indent: 0;
    }

    .btn-primary {
      background-color: #ea701b;
      border: none;
      padding: 8px 15px;
      border-radius: 5px;

      &:hover {
        background-color: #b44100;
      }
    }

    .details {
      padding-top: 10px;
      color: #647778;
      font-weight: 500;
      font-size: 12px;
      margin-bottom: 0px;
    }

    .rtb-sub-title {
      color: #ea701b;
      font-size: 20px;
      padding: 10px 0 5px;
      font-family: proxima-nova, Arial, sans-serif;
    }

    .section-search-hero {
      background-color: #FFF5ED;
      color: #0C2C48;

      .fa-info-circle {
        font-size: 16px;
      }

      .search-group {
        width: 100%;
        display: inline-block;
        padding: 15px 15px 20px 15px;

        &.buy, &.sell, &.preapproval {
          background-size: 140px;
          background-repeat: no-repeat;
          background-position: bottom right;
        }

        .hero-rule {
          display: inline-block;
          width: 40px;
          margin-top: 15px;
          margin-bottom: 5px;
          border: 3px solid #ea701b;
        }

        .search-bar {
          .input-group {
            width: 75%;
          }

          .form-control {
            font-size: 15px;
          }
        }
      }

      .hero-right.buy {
        display: none;
        width: 100%;
        height: 200px;
        background-image: url("../assets/zero-listing-fees.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }

      .hero-right.sell {
        display: none;
        width: 100%;
        height: 400px;
        background-image: url("../assets/veteran-family-moving-after-selling-home.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .hero-right.preapproval {
        display: none;
        width: 100%;
        height: 400px;
        background-image: url("../assets/best-mortgage-preapproved.png");
        background-repeat: no-repeat;

        background-position: center;
        background-size: cover;
      }

      h1 {
        font-size: 25px;
        line-height: 30px;
        font-weight: bold;
        font-family: proxima-nova, Arial, sans-serif;
        margin-bottom: 20px;
      }

      .tab-container {
        margin-top: 25px;
        margin-bottom: 5px;

        .tab {
          text-align: center;
          display: inline-block;
          min-width: 60px;
          max-width: 200px;
          margin: 10px 5px 15px 5px;
          font-size: 16px;
          font-family: proxima-nova, Arial, sans-serif;

          a {
            color: #0C2C48;
            width: 100%;
            padding: 5px 10px 5px 10px;
            text-decoration: none;
            border-bottom: 5px solid transparent;

            &:hover {
              color: #b44100;
              border-bottom: 5px solid #b44100;
            }
          }

          &.active {
            a {
              border-bottom: 5px solid #F47C28;

              &:hover {
                color: #F47C28;
              }
            }
          }
        }
      }
    }

    h2 {
      font-weight: 700;
      font-size: 22px;
      margin-bottom: 15px;
    }

    .rtb-flex {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      align-content: center;
      flex-wrap: wrap;
      min-height: 70px;

      figure {
        width: 100%;

        img, iframe {
          margin: 25px 0;
          width: 320px;
          background-color: #FFF5ED;
          border-radius: 15px;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }

        figcaption {
          display: none;
        }
      }

      .rtb-text {
        padding: 25px 15px;
        max-width: 100%;
      }
    }

    .section-rates, .section-down-payment {

      margin-top: 25px;

      svg {
        margin-right: 5px;
      }

      .fa-chevron-double-down {
        margin-right: 10px;
        color: #ea701b;
      }

      .fa-percentage {
        margin-right: 10px;
        color: #ea701b;
      }

      .box-container-wrapper {
        border: 1px solid #FFF0E5;
        border-radius: 25px;
        padding: 15px;
        margin: 15px;
        width: 100%;

        .box-container {
          margin-bottom: 0px;
          column-gap: 20px;

          .d-flex {
            justify-content: center;
          }
        }

        .box-card {
          text-align: center;
          margin: 20px 30px;


          .audience {
            margin-top: 15px;
            font-weight: bold;
            font-size: 15px;
            line-height: 20px;
          }

          .program {
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
          }

          .number {
            font-size: 25px;
            color: #ea701b;
            line-height: 60px;
          }

          .points {

          }
        }

        .rates-last-updated {
          margin-top: 20px;
          font-size: 14px;
          font-family: proxima-nova, Arial, sans-serif;
        }
      }
    }

    .section-reviews {
      padding: 45px 15px 25px 15px;
      column-gap: 10px;
      text-align: center;
      justify-content: center;

      & > div {
        margin: 10px;
      }

      img, svg {
        margin: 10px 10px 0 10px;
        max-height: 40px;
        max-width: 110px;
      }

      .caption-title {
        font-family: proxima-nova, Arial, sans-serif;
        font-size: 14px;
        color: #435759;
        font-weight: 600;
        margin-bottom: 0px;
        margin-block-end: 0em;
      }

      .caption-text {
        font-size: 12px;
        color: #435759;
        font-weight: 500;
      }
    }

    .section-savings {
      min-height: 400px;
      margin-top: 25px;

      svg {
        margin-right: 5px;
      }

      .circle {
        color: white;
        background-color: #0C2C48;
        border-radius: 5px;
        height: 25px;
        display: block;
        text-align: center;
      }

      .fa-sack-dollar {
        color: #007f4e;
        margin-right: 15px;
      }

      .savings {
        color: #007f4e;
      }

      .savings-header {
        font-family: proxima-nova, Arial, sans-serif;
        font-size: 14px;
        font-weight: bold;
        color: #0C2C48;
        padding-bottom: 5px;
        margin-bottom: 10px;
        border-bottom: 1px solid #e5e5e4;
      }

      .savings-one {
        padding-top: 7px;
        font-family: proxima-nova, Arial, sans-serif;
        background-color: #ea701b;
        color: white;
        font-weight: bold;
        vertical-align: middle;
        text-align: center;
      }

      .savings-two {
        font-family: proxima-nova, Arial, sans-serif;

        .form-control {
          font-size: 13px;
        }
      }

      .savings-three {
        padding-top: 5px;
        font-size: 15px;
      }
    }

    .section-data {
      margin-top: 15px;
      padding: 15px;

      .capcenter-logo {
        margin-top: 5px;
        height: 30px;
        display: inline-block;

      }

      h2 {
        line-height: 35px;
      }

      h3 {
        color: #ea701b;
        font-family: Omnes-pro, Helvetica, Arial, sans-serif;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        margin: 30px 25px 10px 25px;
        width: 200px;
        text-align: center;
      }

      p {
        text-align: center;
        width: 200px;
        font-family: Omnes-pro, Helvetica, Arial, sans-serif;
        font-size: 16px;
        line-height: 22px;
        margin: 15px 25px;
      }

      div.large {
        h3 {
          text-align: center;
        }
      }

      .rtb-flex {
        justify-content: center;
      }
    }

    .section-process {
      margin-top: 25px;
      padding: 25px 25px;

      .fa-project-diagram {
        color: #ea701b;
      }

      h2 {
        text-align: center;
        margin-bottom: 25px;
      }

      ol {
        margin: 0 0 1.5em;
        padding: 0;
        counter-reset: item;
      }

      ol > li {
        margin: 0 0 15px 0;
        padding: 0 0 0 2em;
        text-indent: -2em;
        list-style-type: none;
        counter-increment: item;
      }

      ol > li:before {
        display: inline-block;
        width: 1.5em;
        padding-right: 15px;
        font-weight: bold;
        text-align: right;
        content: counter(item) ".";
      }
    }

    .section-description {
      margin-top: 5px;
      padding: 25px 15px;

      &.rtb-flex {
        justify-content: center;
      }

      svg {
        margin-right: 5px;
        height: 35px;
      }

      h2 {
        text-align: center;
        margin-bottom: 25px;
      }
    }

    .section-team, .section-savings {
      .fa-users, .fa-sign {
        color: #ea701b;
      }

      .profile-container {
        display: flex;
        margin-bottom: 25px;

        .profile {
          text-align: center;
          width: 22%;

          .profile-image {
            border-radius: 50%;
            width: 100px;
          }

          .profile-name {
            text-align: center;
            font-size: 14px;
            margin-top: 5px;
            margin-bottom: 0;
            font-weight: bold;
            min-width: 100px;
          }

          .profile-title {
            text-align: center;
            font-size: 12px;
            min-width: 100px;
          }
        }
      }
    }

    .section-faq {
      margin-top: 15px;
      padding: 25px 15px;

      .fa-question-circle {
        color: #ea701b;
      }

      .rtb-flex {
        margin-top: 25px;
        margin-bottom: 25px;

        div {
          width: 100%;
        }

        a {
          cursor: pointer;
          text-decoration: none;

          &:hover {
            text-decoration: none;
          }
        }
      }

      h3 {
        color: #0C2C48;
        font-size: 14px;
        padding: 15px 0px 15px 0px;
        border-radius: 5px;
        margin-bottom: 0;

        &:hover {
          background-color: #FFF5ED;
        }

        .faq-number {
          margin-right: 15px;
        }

        i {
          display: none;
          margin-top: 5px;
          width: 50px;
          text-align: center;
          float: right;
        }
      }

      article {
      }

      .faq-answer {
        padding: 25px;
      }

      .show-more {
        margin-top: 25px;
        text-align: center;
      }

      .show-more-link {
        color: #0C2C48;
      }
    }

    .section-next-steps {
      .fa-check-square {
        color: #ea701b;
      }

      .figure-wrapper {
        width: 100%;
        text-align: center;
      }
    }

    .section-listings {
      padding: 15px;

      .capcenter-logo {
        height: 25px;
        display: inline-block;
      }

      .rtb-flex {
        padding: 25px;
      }

      h2 {
      }

      img {
        max-height: 400px;
        max-width: 250px;
        background-color: #FFF5ED;
      }

      .listing-card {
        border-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        border: 1px solid transparent;

        &:hover {
          text-decoration: none;
          border: 1px solid #ea701b;
        }

        .listing-photo {
          img {
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
          }
        }

        .listing-details {
          padding: 15px;
          color: #0C2C48;

          .listing-price {
            font-family: proxima-nova, Arial, sans-serif;
            font-size: 30px;
            line-height: 30px;
            margin: 0;
            color: #ea701b;
          }

          .listing-features {
            font-size: 14px;
          }

          .listing-mls {
            margin-top: 10px;
            margin-bottom: 5px;
            font-size: 12px;
          }
        }
      }
    }

    .section-homes-for-sale {
      padding: 25px 15px;

      .fa-search {
        color: #ea701b;
      }

      .rtb-flex {
        margin-top: 15px;
        margin-bottom: 15px;
        align-items: flex-start;
        justify-content: center;
      }

      h3 {
        font-size: 14px;
        margin: 10px 0px;
        font-weight: bold;

        a {
          color: #0C2C48;
        }
      }

      h4 {
        font-size: 12px;
        margin: 10px 0px;
        font-weight: normal;

        a {
          color: #0C2C48;
        }

      }

      .homes-for-sale-state {
        margin: 15px 15px;
      }

      .homes-for-sale-city {
      }

      .show-more {
        display: none;
        width: 100%;
        font-size: 18px;
        text-align: center;
      }

      .show-more-link {
        width: 100%;
        font-size: 14px;
        text-align: center;
        color: #0C2C48;
      }
    }
  }

}

@media (min-width: $medium) {  ////////////////////////////////////////////////////////////////////////////////////////////////


  main.realty {

    .details {
      padding-top: 10px;
      font-size: 12px;
      margin-bottom: 0px;
    }

    .rtb-sub-title {
      font-size: 20px;
      padding: 10px 0 5px;
    }

    .section-search-hero {
      .fa-info-circle {
        font-size: 16px;
      }

      .row {
        height: auto;
      }

      .search-group {
        width: 100%;
        padding: 30px 100px 60px 130px;

        &.buy, &.sell, &.preapproval {
          background-size: 200px;
        }


        .hero-rule {
          width: 70px;
          margin-top: 20px;
          margin-bottom: 15px;
        }
      }

      .hero-right.buy {
        display:none;
        width: 100%;
        height: 400px;
        background-image: url("../assets/zero-listing-fees.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
      }

      .hero-right.sell {
        width: 100%;
        height: 400px;
        background-image: url("../assets/veteran-family-moving-after-selling-home.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .hero-right.preapproval {
        width:100%;
        height: 400px;
        background-image: url("../assets/best-mortgage-preapproved.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      h1 {
        font-size: 40px;
        line-height: 53px;
        margin-bottom: 20px;
      }

      .tab-container {
        margin-top: 40px;
        margin-bottom: 5px;

        .tab {
          display: inline-block;
          min-width: 30px;
          max-width: 200px;
          margin: 10px 20px 20px 0;
          font-size: 17px;

          a {
            width: 100%;
            padding: 5px 10px 5px 10px;

            &:hover {
              border-bottom: 5px solid #b44100;
            }
          }
        }
      }
    }

    h2 {
      font-size: 35px;
    }

    .rtb-flex {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      align-content: center;
      flex-wrap: wrap;
      min-height: 70px;

      figure {
        width: 100%;

        img, iframe {
          margin: 25px 0;
          min-width: 400px;
          height: auto;
          border-radius: 15px;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }

        figcaption {
          display:none;
        }
      }

      .rtb-text {
        width: 70%;
        padding: 25px 0;
      }
    }

    .section-rates, .section-down-payment {
      svg {
        margin-right: 5px;
      }

      .fa-chevron-double-down {
        margin-right: 10px;
      }

      .fa-percentage {
        margin-right: 10px;
      }

      .rates-last-updated {
        font-size: 14px;
      }

      .box-container-wrapper {
        border-radius: 25px;
        padding: 25px;
        margin: 50px;

        .box-container {
          margin-bottom: 15px;
        }

        .box-card {
          margin: 40px;
          .program {
            font-size: 24px;
          }
          .number {
            font-size: 25px;
          }
          .points {

          }
        }
      }
    }

    .section-reviews {
      padding: 15px 15px 25px 15px;
      column-gap: 10px;

      img, svg {
        margin: 10px 10px 0 10px;
        max-height: 50px;
        max-width: 150px;
      }
      .caption-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0px;
        margin-block-end: 0em;
      }
      .caption-text {
        font-size: 12px;
      }
    }

    .section-savings {
      min-height: 400px;
      row-gap: 20px;

      &.rtb-flex {
        justify-content: space-evenly;
      }

      svg {
        margin-right: 5px;
      }

      .circle {
        border-radius: 5px;
        height: 25px;
      }

      .fa-sack-dollar {
        margin-right: 15px;
      }

      .savings {
      }

      .savings-header {
        font-size: 14px;
        padding-bottom: 5px;
        margin-bottom: 10px;
      }

      .savings-one {
        padding-top: 7px;
      }
      .savings-two {
        .form-control {
          font-size: 18px;
        }
      }
      .savings-three {
        padding-top: 5px;
        font-size: 18px;

        &.total {
          font-size: 20px;
        }
      }
    }

    .section-data {
      padding: 50px;

      h2 {
        text-align: center;
      }

      h3 {
        color: #ea701b;
        font-size: 35px;
        line-height: 35px;
        font-weight: 600;
        margin: 50px 25px 10px 25px;
        width: 200px;
      }
      p {
        width: 200px;
        font-size: 18px;
        line-height: 22px;
        margin: 15px 25px;
      }
    }

    .section-process {
      padding: 25px 25px;

      &.rtb-flex {
        justify-content: center;
      }

      & > div {
        width: 80%;
      }

      .fa-project-diagram {
      }

      h2 {
        margin-bottom: 25px;
      }

      ol {
        margin:0 0 1.5em;
        padding:0;
      }

      ol>li {
        margin:0 0 15px 0;
        padding:0 0 0 2em;
        text-indent:-2em;
      }

      ol>li:before {
        width:1.5em;
        padding-right:15px;
      }
    }

    .section-description {
      padding: 25px 75px;

      svg {
        margin-top: 5px;
        margin-right: 5px;
      }

      h2 {
        margin-bottom: 25px;
      }
    }

    .section-team, .section-savings {
      .fa-users, .fa-sign {
      }

      .profile-container {
        margin-bottom: 25px;

        .profile {
          width: 25%;

          .profile-image {
            max-width: 100px;
            max-width: 100px;
          }

          .profile-name {
            font-size: 14px;
            margin-top: 5px;
            margin-bottom: 0;
            font-weight: bold;
          }
          .profile-title {
            font-size: 12px;
          }
        }
      }
    }

    .section-faq {
      padding: 25px 25px;

      .fa-question-circle {
      }

      .rtb-flex {
        margin-top: 50px;
        margin-bottom: 25px;

        div {
          width: 100%;
        }
      }

      h2 {
        text-align: center;
      }

      h3 {
        font-size: 20px;
        padding: 15px 15px 15px 15px;
        border-radius: 5px;
        margin-bottom: 0;

        &:hover {
        }

        .faq-number {
          margin-right: 15px;
        }

        i {
          display: block;
          width: 50px;
        }
      }

      article {
      }

      .faq-answer {
        padding: 25px;
      }

      .show-more {
        margin-top: 25px;
      }

      .show-more-link {
      }
    }

    .section-next-steps {
      .fa-check-square {
      }
    }

    .section-listings {
      padding: 25px;

      .capcenter-logo {
        height: 35px;
        margin-top: 5px;
      }

      .rtb-flex {
        padding: 25px;
      }

      h2 {
        text-align: center;
      }

      img {
        max-height: 400px;
        max-width: 250px;
      }

      .listing-card {
        border-radius: 15px;

        .listing-photo {
          img {
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
          }
        }
        .listing-details {
          padding: 15px;

          .listing-price {
            font-size: 30px;
            line-height: 30px;
            margin: 0;
          }

          .listing-features {
            font-size: 14px;
          }
          .listing-mls {
            margin-top: 10px;
            margin-bottom: 5px;
            font-size: 12px;
          }
        }
      }
    }

    .section-homes-for-sale {
      padding: 25px 25px;

      .rtb-flex {
        margin-top: 15px;
        margin-bottom: 15px;
      }

      h2 {
        text-align: center;
      }

      h3 {
        font-size: 14px;
        margin: 10px 25px;
        font-weight: bold;
      }

      h4 {
        font-size: 12px;
        margin: 10px 25px;
      }

      .homes-for-sale-state {
        margin: 15px 0;
      }
      .homes-for-sale-city {
      }

      .show-more {
        width: 100%;
        font-size: 18px;
      }
      .show-more-link {
        width: 100%;
        font-size: 14px;
      }
    }
  }
}

@media (min-width: $large) {  ////////////////////////////////////////////////////////////////////////////////////////////////


  main.realty {

    .details {
      padding-top: 10px;
      font-size: 12px;
      margin-bottom: 0px;
    }

    .rtb-sub-title {
      font-size: 20px;
      padding: 10px 0 5px;
    }

    .section-search-hero {
      .fa-info-circle {
        font-size: 16px;
      }

      .search-group {
        width: 100%;
        padding: 30px 100px 60px 130px;

        &.buy, &.sell, &.preapproval {
          background-size: 300px;
        }

        .hero-rule {
          width: 70px;
          margin-top: 20px;
          margin-bottom: 15px;
        }
      }

      .hero-right.buy {
        width: 100%;
        height: 400px;
        background-image: url("../assets/zero-listing-fees.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
      }

      .hero-right.sell {
        width: 100%;
        height: 400px;
        background-image: url("../assets/veteran-family-moving-after-selling-home.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .hero-right.preapproval {
        width:100%;
        height: 400px;
        background-image: url("../assets/best-mortgage-preapproved.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      h1 {
        font-size: 45px;
        line-height: 55px;
        margin-bottom: 20px;
      }

      .tab-container {
        margin-top: 40px;
        margin-bottom: 5px;

        .tab {
          display: inline-block;
          min-width: 30px;
          max-width: 200px;
          margin: 10px 20px 20px 0;
          font-size: 22px;

          a {
            width: 100%;
            padding: 5px 10px 5px 10px;

            &:hover {
              border-bottom: 5px solid #b44100;
            }
          }
        }
      }
    }

    h2 {
      font-size: 35px;
    }

    .rtb-flex {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      align-content: center;
      flex-wrap: wrap;
      min-height: 70px;

      figure {
        width: 100%;

        img, iframe {
          margin: 25px 0;
          min-width: 400px;
          height: auto;
          border-radius: 15px;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }

        figcaption {
          display:none;
        }
      }

      .rtb-text {
        max-width: 100%;
        padding: 25px 0;
      }
    }

    .section-rates, .section-down-payment {
      svg {
        margin-right: 5px;
      }

      .fa-chevron-double-down {
        margin-right: 10px;
      }

      .fa-percentage {
        margin-right: 10px;
      }

      .rates-last-updated {
        font-size: 14px;
      }

      .box-container-wrapper {
        border-radius: 25px;
        padding: 25px;
        margin: 50px;

        .box-container {
          margin-bottom: 15px;
        }

        .box-card {
          margin: 40px;
          .program {
            font-size: 24px;
          }
          .number {
            font-size: 25px;
          }
          .points {

          }
        }
      }
    }

    .section-reviews {
      padding: 15px 15px 25px 15px;
      column-gap: 10px;

      img, svg {
        margin: 10px 10px 0 10px;
        max-height: 50px;
        max-width: 150px;
      }
      .caption-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0px;
        margin-block-end: 0em;
      }
      .caption-text {
        font-size: 12px;
      }
    }

    .section-savings {
      min-height: 400px;
      row-gap: 100px;

      svg {
        margin-right: 5px;
      }

      .circle {
        border-radius: 5px;
        height: 25px;
      }

      .fa-sack-dollar {
        margin-right: 15px;
      }

      .savings {
      }

      .savings-header {
        font-size: 14px;
        padding-bottom: 5px;
        margin-bottom: 10px;
      }

      .savings-one {
        padding-top: 7px;
      }
      .savings-two {
      }
      .savings-three {
        padding-top: 5px;
        font-size: 18px;
        &.total {
          font-size: 20px;
        }
      }
    }

    .section-data {
      padding: 50px;

      h3 {
        color: #ea701b;
        font-size: 35px;
        line-height: 35px;
        font-weight: 600;
        margin: 50px 25px 10px 25px;
        width: 200px;
      }
      p {
        width: 200px;
        font-size: 18px;
        line-height: 22px;
        margin: 15px 25px;
      }

      div.large {
        width: 25%;
        margin: 0 25px;

        h3.large {
          width: 100%;
          color: #ea701b;
          font-size: 25px;
          line-height: 30px;
          font-weight: 600;
          margin: 50px 25px 10px 25px;
        }
        p.large {
          text-align: left;
          width: 100%;
          font-size: 16px;
          line-height: 22px;
          margin: 15px 25px;
        }
      }

    }

    .section-process {
      padding: 25px 25px;

      .fa-project-diagram {
      }

      h2 {
        margin-bottom: 25px;
      }

      ol {
        margin:0 0 1.5em;
        padding:0;
      }

      ol>li {
        margin:0 0 15px 0;
        padding:0 0 0 2em;
        text-indent:-2em;
      }

      ol>li:before {
        width:1.5em;
        padding-right:15px;
      }
    }

    .section-description {
      padding: 25px 150px;

      svg {
        margin-right: 5px;
      }

      h2 {
        margin-bottom: 25px;
      }
    }

    .section-team, .section-savings {
      .fa-users, .fa-sign {
      }

      .profile-container {
        margin-bottom: 25px;

        .profile {
          width: 25%;

          .profile-image {
            max-width: 100px;
            max-width: 100px;
          }

          .profile-name {
            font-size: 14px;
            margin-top: 5px;
            margin-bottom: 0;
            font-weight: bold;
          }
          .profile-title {
            font-size: 12px;
          }
        }
      }
    }

    .section-faq {
      padding: 25px 25px;

      .fa-question-circle {
      }

      .rtb-flex {
        margin-top: 50px;
        margin-bottom: 25px;

        div {
          width: 800px;
        }
      }

      h3 {
        height: 50px;
        font-size: 20px;
        padding: 15px 15px 0 15px;
        border-radius: 5px;
        margin-bottom: 0;

        &:hover {
        }

        .faq-number {
          margin-right: 15px;
        }

        i {
          width: 50px;
        }
      }

      article {
      }

      .faq-answer {
        padding: 25px;
      }

      .show-more {
        margin-top: 25px;
      }

      .show-more-link {
      }
    }

    .section-next-steps {
      .fa-check-square {
      }
    }

    .section-listings {
      padding: 25px;

      .rtb-flex {
        padding: 25px;
      }

      h2 {
      }
      img {
        max-height: 400px;
        max-width: 250px;
      }

      .listing-card {
        border-radius: 15px;

        .listing-photo {
          img {
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
          }
        }
        .listing-details {
          padding: 15px;

          .listing-price {
            font-size: 30px;
            line-height: 30px;
            margin: 0;
          }

          .listing-features {
            font-size: 14px;
          }
          .listing-mls {
            margin-top: 10px;
            margin-bottom: 5px;
            font-size: 12px;
          }
        }
      }
    }

    .section-homes-for-sale {
      padding: 25px 25px;

      .rtb-flex {
        margin-top: 15px;
        margin-bottom: 15px;
      }

      h3 {
        font-size: 14px;
        margin: 10px 25px;
        font-weight: bold;
      }

      h4 {
        font-size: 12px;
        margin: 10px 25px;
      }

      .homes-for-sale-state {
        margin: 15px 0;
      }
      .homes-for-sale-city {
      }

      .show-more {
        width: 100%;
        font-size: 18px;
      }
      .show-more-link {
        width: 100%;
        font-size: 14px;
      }
    }
  }
}

@media (min-width: $xLarge) { ////////////////////////////////////////////////////////////////////////////////////////////////



  main.realty {

    .details {
      padding-top: 10px;
      font-size: 12px;
      margin-bottom: 0px;
    }

    .rtb-sub-title {
      font-size: 20px;
      padding: 10px 0 5px;
    }

    .section-search-hero {
      .fa-info-circle {
        font-size: 16px;
      }

      .search-group {
        width: 50%;
        padding: 30px 60px 60px 60px;

        &.buy, &.sell, &.preapproval {
          background: none;
        }

        .hero-rule {
          width: 70px;
          margin-top: 20px;
          margin-bottom: 15px;
        }

        .search-bar {
          .input-group {
            width: 95%;
          }
          .form-control {
            font-size: 15px;
          }
        }
      }

      .hero-right.buy {
        display: block;
        width: 50%;
        height: 400px;
        background-image: url("../assets/zero-listing-fees.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
      }

      .hero-right.sell {
        display:block;
        width: 50%;
        height: 400px;
        background-image: url("../assets/veteran-family-moving-after-selling-home.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .hero-right.preapproval {
        display:block;
        width:50%;
        height: 400px;
        background-image: url("../assets/best-mortgage-preapproved.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      h1 {
        font-size: 36px;
        line-height: 45px;
        margin-bottom: 20px;
      }

      .tab-container {
        margin-top: 40px;
        margin-bottom: 5px;

        .tab {
          display: inline-block;
          min-width: 30px;
          max-width: 200px;
          margin: 10px 20px 20px 0;
          font-size: 16px;

          a {
            width: 100%;
            padding: 5px 10px 5px 10px;

            &:hover {
              border-bottom: 5px solid #b44100;
            }
          }
        }
      }
    }

    h2 {
      font-size: 30px;
    }

    .rtb-flex {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      align-content: center;
      flex-wrap: wrap;
      min-height: 70px;

      figure {
        width: 100%;

        img, iframe {
          margin: 25px 0;
          min-width: 400px;
          height: auto;
          border-radius: 15px;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }

        figcaption {
          display:none;
        }
      }

      .rtb-text {
        width: 45%;
        padding: 25px 0;
      }
    }

    .section-rates, .section-down-payment {
      svg {
        margin-right: 5px;
      }

      .fa-chevron-double-down {
        margin-right: 10px;
      }

      .fa-percentage {
        margin-right: 10px;
      }

      .rates-last-updated {
        font-size: 14px;
      }

      .box-container-wrapper {
        border-radius: 25px;
        padding: 25px;
        margin: 50px;
        max-width: 40%;

        .box-container {
          margin-bottom: 15px;
        }

        .box-card {
          margin: 20px;
          .program {
            font-size: 18px;
            font-family: proxima-nova, Arial, sans-serif;
          }
          .number {
            font-size: 25px;
          }
          .points {

          }
        }
      }
    }

    .section-reviews {
      padding: 15px 15px 25px 15px;
      column-gap: 10px;

      img, svg {
        margin: 10px 10px 0 10px;
        max-height: 50px;
        max-width: 150px;
      }
      .caption-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0px;
        margin-block-end: 0em;
      }
      .caption-text {
        font-size: 12px;
      }
    }

    .section-savings {
      min-height: 400px;
      row-gap: 100px;

      svg {
        margin-right: 5px;
      }

      .circle {
        border-radius: 5px;
        height: 25px;
      }

      .fa-sack-dollar {
        margin-right: 15px;
      }

      .savings {
      }

      .savings-header {
        font-size: 14px;
        padding-bottom: 5px;
        margin-bottom: 10px;
      }

      .savings-one {
        padding-top: 7px;
      }
      .savings-two {
      }
      .savings-three {
        padding-top: 5px;
        font-size: 18px;
        &.total {
          font-size: 20px;
        }
      }
    }

    .section-data {
      padding: 50px;

      h2 {
        text-align: center;
      }

      h3 {
        color: #ea701b;
        font-size: 35px;
        line-height: 35px;
        font-weight: 600;
        margin: 50px 25px 10px 25px;
        width: 200px;
      }
      p {
        width: 200px;
        font-size: 18px;
        line-height: 22px;
        margin: 15px 25px;
      }
    }

    .section-process {
      padding: 25px 25px;

      .fa-project-diagram {
      }

      h2 {
        margin-bottom: 25px;
      }

      ol {
        margin:0 0 1.5em;
        padding:0;
      }

      ol>li {
        margin:0 0 15px 0;
        padding:0 0 0 2em;
        text-indent:-2em;
      }

      ol>li:before {
        width:1.5em;
        padding-right:15px;
      }
    }

    .section-description {
      padding: 25px 150px;

      svg {
        margin-right: 5px;
      }

      h2 {
        margin-bottom: 25px;
      }
    }

    .section-team, .section-savings {
      .fa-users, .fa-sign {
      }

      .profile-container {
        margin-bottom: 25px;

        .profile {
          width: 25%;

          .profile-image {
            max-width: 100px;
            max-width: 100px;
          }

          .profile-name {
            font-size: 14px;
            margin-top: 5px;
            margin-bottom: 0;
            font-weight: bold;
          }
          .profile-title {
            font-size: 12px;
          }
        }
      }
    }

    .section-faq {
      padding: 25px 25px;

      .fa-question-circle {
      }

      .rtb-flex {
        margin-top: 50px;
        margin-bottom: 25px;

        div {
          width: 800px;
        }
      }

      h3 {
        height: 55px;
        font-size: 20px;
        padding: 15px 15px 0 15px;
        border-radius: 5px;
        margin-bottom: 0;

        &:hover {
        }

        .faq-number {
          margin-right: 15px;
        }

        i {
          width: 50px;
        }
      }

      article {
      }

      .faq-answer {
        padding: 25px;
      }

      .show-more {
        margin-top: 25px;
      }

      .show-more-link {
      }
    }

    .section-next-steps {
      .fa-check-square {
      }
    }

    .section-listings {
      padding: 25px;

      .rtb-flex {
        padding: 25px;
      }

      h2 {
      }
      img {
        max-height: 400px;
        max-width: 250px;
      }

      .listing-card {
        border-radius: 15px;

        .listing-photo {
          img {
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
          }
        }
        .listing-details {
          padding: 15px;

          .listing-price {
            font-size: 30px;
            line-height: 30px;
            margin: 0;
          }

          .listing-features {
            font-size: 14px;
          }
          .listing-mls {
            margin-top: 10px;
            margin-bottom: 5px;
            font-size: 12px;
          }
        }
      }
    }

    .section-homes-for-sale {
      padding: 25px 25px;

      .rtb-flex {
        margin-top: 15px;
        margin-bottom: 15px;
      }

      h3 {
        font-size: 14px;
        margin: 10px 25px;
        font-weight: bold;
      }

      h4 {
        font-size: 12px;
        margin: 10px 25px;
      }

      .homes-for-sale-state {
        margin: 15px 0;
      }
      .homes-for-sale-city {
      }

      .show-more {
        width: 100%;
        font-size: 18px;
      }
      .show-more-link {
        width: 100%;
        font-size: 14px;
      }
    }
  }

}

@media (min-width: $xxLarge) { ////////////////////////////////////////////////////////////////////////////////////////////////

  main.realty {

    h2 {
      font-size: 35px;
    }

    .section-search-hero {
      h1 {
        font-size: 45px;
        line-height: 55px;
        margin-bottom: 20px;
      }
    }

    .section-rates {
      .box-container-wrapper {
        .box-container {
          .d-flex {
            justify-content: space-evenly;
          }
        }
      }
    }

    .section-description {
      padding: 25px 150px;
    }
  }
}
